import moment from "moment";
import React, { useState } from "react";
import * as yup from "yup";
import SaveIcon from "../../../assets/images/save-icon.svg";
import Loading from "../../../assets/images/loading-roll.svg";
import { paymentMethodListValueString, paymentMethodListValueStringConciliador } from "../../../utils/paymentMethodList";
import * as S from "./styles";
import { GlobalRow } from "../../../utils/GlobalRow";
import { GlobalColumn } from "../../../utils/GlobalColumn";
import { InvoicePaymentInfo } from "../Models";
import {
  InputContainerV3,
  SelectContainerV3,
} from "../../../components/InputV3";
import {
  formatFloatToCurrency,
  formatStringToFloat,
} from "../../../utils/StringToCurrency";
import { snackbarErrorHandler } from "../../../utils/snackbarErrorHandler";
import { useSnackbar } from "notistack";
import {
  postCreateRequestProcess,
  postPaymentRequestProcess,
} from "../viewModel";
import { PaymentRequestDetail } from "./Models";

interface ModalProps {
  setModalVisible(b: boolean): void;
  payment: InvoicePaymentInfo;
  refreshPage: () => void;
}

export function ConciliadorPaymentModal({
  setModalVisible,
  payment,
  refreshPage,
}: ModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [isError, setIsError] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [paymentRequestDetail, setPaymentRequestDetail] = useState<
    PaymentRequestDetail | undefined
  >();

  const [paymentDate, setPaymentDate] = useState("");
  const [paymentHour, setPaymentHour] = useState("");

  const [paymentValue, setPaymentValue] = useState<number>(0);
  const [paymentSelect, setPaymentSelect] = useState("");

  const [paymentIdentifier, setPaymentIdentifier] = useState("");
  const [paymentReason, setPaymentReason] = useState("");

  const [showprice, setShowPrice] = useState("");

  function onChangeHandlerPrice(value: string) {
    const showPriceString = formatFloatToCurrency(formatStringToFloat(value));
    const paymentValueString = formatStringToFloat(value);

    setShowPrice(showPriceString);
    setPaymentValue(paymentValueString);
  }

  async function createPostObj() {
    try {
      const schema = yup.object().shape({
        total: yup.number().required().min(0.01),
        reason: yup.string().required(),
        payment_method: yup.string().required(),
        payment_date: yup.string().required().min(5),
      });
      const postObj = {
        total: paymentValue,
        reason: paymentReason,
        payment_method: paymentSelect,
        payment_date: `${paymentDate} ${paymentHour}:00`,
        atk: paymentIdentifier || null,
      };

      await schema.validate(postObj, {
        abortEarly: false,
      });
      return postObj;
    } catch (error) {
      const validationError = {} as any;
      if (error instanceof yup.ValidationError) {
        error.inner.forEach((err) => {
          if (err.path) validationError[err.path] = true;
        });
      }
      setIsError(validationError);
      return undefined;
    }
  }

  async function postCreateRequest() {
    try {
      const data = await postCreateRequestProcess(payment.id);
      setPaymentRequestDetail(data);
    } catch (error) {
      setModalVisible(false);
      snackbarErrorHandler(error, enqueueSnackbar, "Fatura não encontrada");
    }
  }

  async function paymentRequestProcess() {
    if (paymentRequestDetail)
      try {
        if (loading) return;
        setLoading(true);
        const postObj = await createPostObj();
        await postPaymentRequestProcess(paymentRequestDetail?.id, postObj);
        enqueueSnackbar("Pagamento efetuado com sucesso", {
          variant: "success",
        });
        setModalVisible(false);
        refreshPage();
      } catch (error) {
        snackbarErrorHandler(
          error,
          enqueueSnackbar,
          "Falha em efetuar pagamento"
        );
      } finally {
        setLoading(false);
      }
  }

  function StringToLocalDate(stringDate: string) {
    return moment(stringDate).format("DD/MM/YYYY");
  }
  function StringToLocalDateHour(stringDate: string) {
    return moment(stringDate).format("DD/MM/YYYY HH:mm");
  }

  function StringToCurrency(value: string) {
    return parseFloat(value).toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      style: "currency",
      currency: "BRL",
    });
  }

  React.useEffect(() => {
    postCreateRequest();
  }, []);

  return (
    <S.Modal style={{ maxWidth: 768 }}>
      <S.ModalHeader>
        <S.HeaderTitle>{"Conciliar pagamento"}</S.HeaderTitle>

        <GlobalRow gap="8px">
          <S.HeaderButton
            onClick={(e) => {
              e.preventDefault();
              setModalVisible(false);
            }}
          >
            Cancelar
          </S.HeaderButton>
          <S.HeaderButtonSave
            onClick={(e) => {
              e.preventDefault();
              paymentRequestProcess();
            }}
          >
            {loading ? (
              <>
                <img className="loading-roll" alt="loading" src={Loading} />
              </>
            ) : (
              <>
                <img src={SaveIcon} alt="save" />
                Salvar
              </>
            )}
          </S.HeaderButtonSave>
        </GlobalRow>
      </S.ModalHeader>

      <S.ModalContainer>
        <S.MenuItem backgroundColor="#E0E0E0">
          <GlobalRow
            alignItems="center"
            justifyContent="space-between"
            className="menu_margin_b-8 menu_item_header"
          >
            <p className="menu_item_title">Detalhes da cobrança</p>
          </GlobalRow>
          <GlobalColumn
            gap="16px"
            alignItems="flex-start"
            className="menu_padding_b-16 item_width-100"
          >
            <S.GridContainer className="item_width-100">
              <GlobalColumn
                alignItems="flex-start"
                justifyContent="flex-start"
                gap="4px"
              >
                <p className="menu_item_label">Guia</p>
                <p className="menu_item_info">{payment.code || "-"}</p>
                <p className="menu_item_info">
                  {payment.content?.description || "-"}
                </p>
              </GlobalColumn>
            </S.GridContainer>

            <S.GridContainer
              gridTemplateColumns="1fr 1fr 1fr"
              className="item_width-100"
            >
              <GlobalColumn
                alignItems="flex-start"
                justifyContent="flex-start"
                gap="4px"
              >
                <p className="menu_item_label">Criado em</p>
                <p className="menu_item_info menu_item_ellipsis">
                  {StringToLocalDateHour(payment.content?.billingPeriod)}
                </p>
              </GlobalColumn>
              <GlobalColumn
                alignItems="flex-start"
                justifyContent="flex-start"
                gap="4px"
              >
                <p className="menu_item_label">Vencimento</p>
                <p className="menu_item_info menu_item_ellipsis">
                  {StringToLocalDate(payment.paymentDueDate)}
                </p>
              </GlobalColumn>
              <GlobalColumn
                alignItems="flex-start"
                justifyContent="flex-start"
                gap="4px"
              >
                <p className="menu_item_label">Valor do documento</p>
                <p className="menu_item_info">
                  {StringToCurrency(`${payment.total}`) || "-"}
                </p>
              </GlobalColumn>
            </S.GridContainer>
            <S.GridContainer className="item_width-100">
              <GlobalColumn
                alignItems="flex-start"
                justifyContent="flex-start"
                gap="4px"
              >
                <p className="menu_item_label">Processo</p>
                <p className="menu_item_info">{payment.contentRef || "-"}</p>
              </GlobalColumn>
            </S.GridContainer>
          </GlobalColumn>
        </S.MenuItem>
        <S.MenuItem>
          <GlobalRow
            alignItems="center"
            justifyContent="space-between"
            className="menu_margin_b-8 menu_item_header"
          >
            <p className="menu_item_title">Dados da conciliação</p>
          </GlobalRow>
          <GlobalColumn
            gap="16px"
            alignItems="flex-start"
            className="menu_padding_b-16 item_width-100"
          >
            <S.GridContainer className="item_width-100">
              <GlobalColumn
                alignItems="flex-start"
                justifyContent="flex-start"
                gap="4px"
              >
                <InputContainerV3
                  // isError={isError.login}
                  isError={isError.payment_date}
                  fieldLabel={"DATA DO PAGAMENTO *"}
                  inputName={paymentDate}
                  setInputField={setPaymentDate}
                  placeholder={"DD/MM/AAAA"}
                  type="date"
                />
              </GlobalColumn>
              <GlobalColumn
                alignItems="flex-start"
                justifyContent="flex-start"
                gap="4px"
              >
                <InputContainerV3
                  // isError={isError.login}
                  isError={isError.payment_date}
                  fieldLabel={"HORA DO PAGAMENTO *"}
                  inputName={paymentHour}
                  setInputField={setPaymentHour}
                  placeholder={"HH:MM"}
                  type="time"
                />
              </GlobalColumn>
            </S.GridContainer>
            <S.GridContainer className="item_width-100">
              <GlobalColumn
                alignItems="flex-start"
                justifyContent="flex-start"
                gap="4px"
              >
                <InputContainerV3
                  isError={isError.total}
                  fieldLabel={"VALOR DO PAGAMENTO *"}
                  inputName={showprice}
                  setInputField={onChangeHandlerPrice}
                />
              </GlobalColumn>
              <GlobalColumn
                alignItems="flex-start"
                justifyContent="flex-start"
                gap="4px"
              >
                <SelectContainerV3
                  // isError={isError.login}
                  isError={isError.payment_method}
                  fieldLabel={"FORMA DE PAGAMENTO *"}
                  inputName={paymentSelect}
                  setInputField={setPaymentSelect}
                  options={paymentMethodListValueStringConciliador}
                  placeholder={"Selecione"}
                />
              </GlobalColumn>
            </S.GridContainer>

            <S.GridContainer
              gridTemplateColumns="1fr"
              className="item_width-100"
            >
              <GlobalColumn
                alignItems="flex-start"
                justifyContent="flex-start"
                gap="4px"
              >
                <InputContainerV3
                  // isError={isError.login}
                  fieldLabel={"identificador da transação (opcional)"}
                  inputName={paymentIdentifier}
                  setInputField={setPaymentIdentifier}
                  placeholder={"NSU, ATK, ITK, PAN..."}
                />
              </GlobalColumn>
            </S.GridContainer>

            <S.GridContainer
              gridTemplateColumns="1fr"
              className="item_width-100"
            >
              <GlobalColumn
                alignItems="flex-start"
                justifyContent="flex-start"
                gap="4px"
              >
                <InputContainerV3
                  // isError={isError.login}
                  isError={isError.reason}
                  fieldLabel={"MOTIVO *"}
                  inputName={paymentReason}
                  setInputField={setPaymentReason}
                  placeholder={"Descreva o motivo..."}
                />
              </GlobalColumn>
            </S.GridContainer>
          </GlobalColumn>
        </S.MenuItem>
      </S.ModalContainer>
    </S.Modal>
  );
}
