const TYPE_DEBIT = 1;
const TYPE_CREDIT = 2;
const TYPE_PRE_PAID = 3;
const TYPE_PRE_PAID_CREDIT = 4;
const TYPE_BOLETO = 5;
const TYPE_PIX = 6;

export const paymentMethodList = [
  { label: "Débito", value: 1 },
  { label: "Crédito", value: 2 },
  { label: "Pré pago", value: 3 },
  { label: "Pré pago crédito", value: 4 },
  { label: "Boleto", value: 5 },
  { label: "Pix", value: 6 },
];

export const paymentMethodListValueString = [
  { label: "Débito", value: '1' },
  { label: "Crédito", value: '2' },
  { label: "Pré pago", value: '3' },
  { label: "Pré pago crédito", value: '4' },
  { label: "Boleto", value: '5' },
  { label: "Pix", value: '6' },
];

export const paymentMethodListValueStringConciliador = [
  { label: "Débito", value: '1' },
  { label: "Crédito", value: '2' },
  { label: "Boleto", value: '5' },
  { label: "Pix", value: '6' },
];
