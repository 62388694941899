import moment from "moment";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { Payment, Transaction } from "../../../models/model";
import { statusesData, transactionStatuses } from "../../../utils/statusesList";
import { paymentMethodList } from "../../../utils/paymentMethodList";
import * as S from "./styles";
import { GlobalRow } from "../../../utils/GlobalRow";
import CloseBlack from "../../../assets/images/close-black.svg";
import Loading from "../../../assets/images/loading-roll-green.svg";

import { GlobalColumn } from "../../../utils/GlobalColumn";
import { getPaymentByIdRequest } from "../viewModel";
import { maskCustomProcess } from "../../../utils/formatacaoDoc";
import { ProofPaymentPDF } from "../../../components/PDF/ProofPaymentPDF";
import { PaymentDetailPDF } from "../../../components/PDF/PaymentDetailPDF";

interface ModalProps {
  setModalVisible(b: boolean): void;
  payment: Payment;
}

export function ModalRelatorioScreen({ setModalVisible, payment }: ModalProps) {
  const [localPayment, setLocalPayment] = useState<Payment>(payment);
  const [loading, setLoading] = useState(true);
  const [hasPrint, setHasPrint] = useState(false);
  function printProof(): void {
    setHasPrint(true);
    setTimeout(() => {
      setHasPrint(false);
    }, 3000);
  }

  const firstInvoice = localPayment?.invoices[0];

  const clientName = payment.transactions[0]
    ? payment.transactions[0].name
    : "-";
  const clientDoc =
    payment.doc && payment.doc.length > 0
      ? payment.doc
      : payment.transactions[0] && payment.transactions[0].doc
      ? payment.transactions[0].doc
      : "";

  const empresa = payment.merchant ? payment.merchant.name : "-";

  const provider = payment.transactions[0]
    ? payment.transactions[0].provider
    : "-";
  const paymentLastTransaction = payment.lastTransaction;

  // const ref = localPayment.transactions[0] ? localPayment.transactions[0].ref : "-";

  function StringToLocalDate(stringDate: string) {
    return moment(stringDate).format("DD/MM/YYYY HH:mm");
  }
  function getStatus() {
    const find = statusesData.find((f) => f.value === localPayment.status);
    if (find) {
      return (
        <GlobalRow justifyContent="flex-start" gap="8px">
          {find.iconColor ? (
            <S.StatusIcon statusColor={find.iconColor} />
          ) : null}{" "}
          {find.label}
        </GlobalRow>
      );
    }
    return localPayment.status;
  }
  function getTransactionStatus(transactionStatus?: number) {
    if (transactionStatus !== undefined) {
      const find = transactionStatuses.find(
        (f) => f.value === transactionStatus
      );
      if (find) {
        return (
          <GlobalRow justifyContent="flex-start" gap="8px">
            {find.iconColor ? (
              <S.StatusIcon statusColor={find.iconColor} />
            ) : null}{" "}
            <p className="menu_item_status_info">{find.label}</p>
          </GlobalRow>
        );
      }
    }
    return (
      <p className="menu_item_status_info">{`${transactionStatus || "-"}`}</p>
    );
  }

  async function getPaymentById() {
    setLoading(true);
    try {
      const data = await getPaymentByIdRequest(localPayment.id);
      if (data.payment) setLocalPayment(data.payment);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  function invoiceDescription(invoice: any): string {
    if (invoice.extras.custa) {
      if (invoice.extras.custa[0]) {
        return invoice.extras.custa[0].descricao;
      }
    }
    if (invoice?.extras?.description) {
      return invoice.extras?.description;
    }
    if (invoice?.description) {
      return invoice.description;
    }

    return "-";
  }

  useEffect(() => {
    getPaymentById();
  }, [payment]);

  function getStatusColor() {
    const data = [
      { label: "", value: -1 },
      { label: "#7CB342", value: 0 },
      { label: "#1588D1", value: 1 },
      { label: "#757575", value: 2 },
      { label: "#E53935", value: 3 },
      { label: "#7CB342", value: 4 },
      { label: "#7CB342", value: 5 },
      { label: "#FFBB33", value: 7 },
      { label: "#5E35B1", value: 8 },
      { label: "#757575", value: 9 },
      { label: "#757575", value: 11 },
    ];
    const find = data.find((f) => f.value === localPayment.status);
    if (find) {
      return find.label;
    }
    return "#757575";
  }

  function StringToCurrency(value: string) {
    return parseFloat(value).toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      style: "currency",
      currency: "BRL",
    });
  }

  function getType(transaction: Transaction) {
    const find = paymentMethodList.find(
      (f) => `${f.value}` === `${transaction.type}`
    );

    return find?.label || "-";
  }

  function maskDocument(preValue: string): string {
    let value = preValue;
    value = value.replace(/\D/g, "");
    if (value.length <= 11) {
      value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
    } else {
      value = value.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        "$1.$2.$3/$4-$5"
      );
    }
    return value;
  }

  function paymentDeviceName() {
    let pdn = localPayment.device ? localPayment.device.name : "-";
    if (pdn.length > 10) {
      pdn = pdn.slice(0, 8) + "...";
    }

    return pdn;
  }

  return (
    <S.Modal style={{ maxWidth: 768 }}>
      <S.ModalHeader>
        <S.HeaderTitle>{"Detalhes da Transação"}</S.HeaderTitle>

        <GlobalRow gap="8px">
          {!loading ? (
            // && localPayment.merchant.id === 6
            <S.HeaderButton
              onClick={() => {
                printProof();
              }}
            >
              {hasPrint ? (
                <img className="loading-roll" alt="loading" src={Loading} />
              ) : (
                <>Baixar PDF</>
              )}
            </S.HeaderButton>
          ) : null}
          <S.HeaderButton
            onClick={(e) => {
              e.preventDefault();
              setModalVisible(false);
            }}
          >
            <img alt="x" src={CloseBlack} />
            Fechar
          </S.HeaderButton>
        </GlobalRow>
      </S.ModalHeader>

      <S.ModalContainer>
        {loading ? (
          <img className="loading-roll" alt="loading" src={Loading} />
        ) : (
          <>
            {" "}
            <S.MenuItem>
              <GlobalRow
                alignItems="center"
                justifyContent="space-between"
                className="menu_margin_b-8 menu_item_header"
              >
                <p className="menu_item_title">Detalhes do pagamento</p>

                <div className="menu_detalhes_status">{getStatus()}</div>
              </GlobalRow>
              <GlobalColumn
                gap="16px"
                alignItems="flex-start"
                className="menu_padding_b-16 item_width-100"
              >
                <S.GridContainer className="item_width-100">
                  <GlobalColumn
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    gap="4px"
                  >
                    <p className="menu_item_label">Total pago</p>
                    <p className="menu_item_info">
                      {StringToCurrency(localPayment.total) || "-"}
                    </p>
                  </GlobalColumn>
                  {paymentLastTransaction?.paymentDate ? (
                    <GlobalColumn
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      gap="4px"
                    >
                      <p className="menu_item_label">PAGO EM</p>
                      <p className="menu_item_info">
                        {StringToLocalDate(paymentLastTransaction?.paymentDate)}
                      </p>
                    </GlobalColumn>
                  ) : null}
                </S.GridContainer>
                <S.GridContainer className="item_width-100">
                  <GlobalColumn
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    gap="4px"
                  >
                    <p className="menu_item_label">Origem</p>
                    <div className="menu_item_grid">
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tool-tip">
                            <span style={{ textTransform: "capitalize" }}>
                              {localPayment.device?.name || "-"}
                            </span>
                          </Tooltip>
                        }
                      >
                        <p className="menu_item_info menu_item_ellipsis">
                          {localPayment?.device?.name}
                        </p>
                      </OverlayTrigger>
                    </div>
                  </GlobalColumn>
                  <GlobalColumn
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    gap="4px"
                  >
                    <p className="menu_item_label">Provedor</p>
                    <p className="menu_item_info">{provider || "-"}</p>
                  </GlobalColumn>
                </S.GridContainer>

                <S.GridContainer
                  gridTemplateColumns="1fr"
                  className="item_width-100"
                >
                  <GlobalColumn
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    gap="4px"
                  >
                    <p className="menu_item_label">
                      Identificador do pagamento
                    </p>
                    <p className="menu_item_info menu_item_ellipsis">
                      {localPayment.id}
                    </p>
                  </GlobalColumn>
                </S.GridContainer>
              </GlobalColumn>
            </S.MenuItem>
            <S.MenuItem>
              <GlobalRow
                alignItems="center"
                justifyContent="space-between"
                className="menu_margin_b-8 menu_item_header"
              >
                <p className="menu_item_title">Detalhes da cobrança</p>
              </GlobalRow>
              <GlobalColumn
                gap="16px"
                alignItems="flex-start"
                className="menu_padding_b-16  item_width-100"
              >
                <S.GridContainer
                  gridTemplateColumns="1fr 1fr 1fr"
                  className="item_width-100"
                >
                  <GlobalColumn
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    gap="4px"
                  >
                    <p className="menu_item_label">Tipo</p>
                    <p className="menu_item_info">Fatura</p>
                  </GlobalColumn>
                  {localPayment.total ? (
                    <GlobalColumn
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      gap="4px"
                    >
                      <p className="menu_item_label">Valor do documento</p>
                      <p className="menu_item_info">
                        {StringToCurrency(localPayment.total)}
                      </p>
                    </GlobalColumn>
                  ) : null}
                  {localPayment.created ? (
                    <GlobalColumn
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      gap="4px"
                    >
                      <p className="menu_item_label">Criado em</p>
                      <p className="menu_item_info">
                        {StringToLocalDate(localPayment.created)}
                      </p>
                    </GlobalColumn>
                  ) : null}
                </S.GridContainer>
                <S.GridContainer
                  gridTemplateColumns="1fr"
                  className="item_width-100"
                >
                  <GlobalColumn
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    gap="4px"
                  >
                    <p className="menu_item_label">Empresa</p>
                    <div className="menu_item_grid">
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tool-tip">
                            <span style={{ textTransform: "capitalize" }}>
                              {empresa || "-"}
                            </span>
                          </Tooltip>
                        }
                      >
                        <p className="menu_item_info menu_item_ellipsis">
                          {empresa || "-"}
                        </p>
                      </OverlayTrigger>
                    </div>
                  </GlobalColumn>
                </S.GridContainer>
                {firstInvoice?.contentRef ? (
                  <S.GridContainer
                    gridTemplateColumns="1fr"
                    className="item_width-100"
                  >
                    <GlobalColumn
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      gap="4px"
                    >
                      <p className="menu_item_label">
                        {firstInvoice.content?.extras
                          ? "Processo"
                          : "Documento"}
                      </p>
                      <p className="menu_item_info menu_item_ellipsis">
                        {firstInvoice.content?.extras
                          ? maskCustomProcess(
                              `${firstInvoice?.contentRef || "-"}`
                            )
                          : maskDocument(`${firstInvoice?.contentRef || "-"}`)}
                      </p>
                    </GlobalColumn>
                  </S.GridContainer>
                ) : null}

                <S.GridContainer
                  gridTemplateColumns="1fr"
                  className="item_width-100"
                >
                  <GlobalColumn
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    gap="4px"
                  >
                    <p className="menu_item_label">
                      {localPayment.merchant.id === 6 ? "Guias" : "Faturas"}
                    </p>
                    {localPayment.invoices.map((invoice) => (
                      <S.GridContainer
                        gridTemplateColumns="1fr 0.3fr"
                        className="item_width-100"
                      >
                        <GlobalColumn alignItems="flex-start">
                          <S.GridContainer
                            gridTemplateColumns="0.3fr 1fr"
                            className="item_width-100"
                          >
                            <p className="menu_item_info menu_item_ellipsis">
                              {localPayment.merchant.id === 6
                                ? invoice.code || "-"
                                : invoice.contentRefDate || "-"}
                            </p>
                            {localPayment.merchant.id === 6 &&
                            invoice.content ? (
                              <>
                                <p className="menu_item_info menu_item_ellipsis">
                                  {invoiceDescription(invoice.content)}
                                </p>
                              </>
                            ) : null}
                          </S.GridContainer>
                          {/* <div className="invoice_custas_container">
                            {invoice.content?.extras?.custa?.map(
                              (custa, index) => (
                                <div className="invoice_max_width" key={index}>
                                  {custa.itemGuia.length ? (
                                    custa.itemGuia.map(
                                      (itemGuia, indexItem) => {
                                        return (
                                          <div
                                            key={indexItem}
                                            className="invoice_row_between invoice_padding_left-16 invoice_max_width"
                                          >
                                            <p className="menu_item_info">
                                              {itemGuia.descricao}
                                            </p>
                                            <p className="menu_item_info">
                                              {`R$ ${itemGuia.valor.toLocaleString(
                                                "pt-BR",
                                                {
                                                  minimumFractionDigits: 2,
                                                }
                                              )}`}
                                            </p>
                                          </div>
                                        );
                                      }
                                    )
                                  ) : (
                                    <div className="invoice_row_between invoice_padding_left-16 invoice_max_width">
                                      <p className="menu_item_info">
                                        {custa.descricao}
                                      </p>
                                      <p className="menu_item_info">
                                        {custa.valorCusta
                                          ? `R$ ${(
                                              custa.valorCusta || 0
                                            ).toLocaleString("pt-BR", {
                                              minimumFractionDigits: 2,
                                            })}`
                                          : null}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              )
                            )}
                          </div> */}
                        </GlobalColumn>

                        {invoice.total ? (
                          <p className="menu_item_info menu_item_info_align-right menu_item_ellipsis">
                            {StringToCurrency(`${invoice.total || 0}`)}
                          </p>
                        ) : null}
                      </S.GridContainer>
                    ))}
                  </GlobalColumn>
                </S.GridContainer>
              </GlobalColumn>
            </S.MenuItem>
            <S.MenuItem>
              <p className="menu_item_title menu_margin_b-8 menu_item_header">
                Detalhes do Cliente
              </p>
              <S.GridContainer className="item_width-100">
                <GlobalColumn
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  gap="4px"
                >
                  <p className="menu_item_label">Nome</p>
                  <p className="menu_item_info menu_item_ellipsis">
                    {clientName}
                  </p>
                </GlobalColumn>
                <GlobalColumn
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  gap="4px"
                >
                  <p className="menu_item_label">documento</p>
                  <p className="menu_item_info menu_item_ellipsis">
                    {clientDoc.length > 0
                      ? maskDocument(clientDoc || "-")
                      : "-"}
                  </p>
                </GlobalColumn>
              </S.GridContainer>
            </S.MenuItem>
            {localPayment.transactions.length > 0 && (
              <S.MenuItem>
                <p className="menu_item_title menu_item_header menu_margin_b-8">
                  Detalhes das transações
                </p>

                {localPayment.transactions.map((transaction, index) => {
                  return (
                    <S.GridContainer
                      gridTemplateColumns="1fr 1fr 1fr"
                      className={`item_width-100 ${
                        index !== localPayment.transactions.length - 1
                          ? "menu_padding_b-24 menu_border_b-gray"
                          : ""
                      } ${index !== 0 ? "menu_padding_t-24" : ""}`}
                    >
                      <GlobalColumn
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        gap="4px"
                      >
                        <p className="menu_item_label ">Tipo</p>
                        <p className="menu_item_info">{`${getType(
                          transaction
                        )} ${`${
                          transaction.installments
                            ? `(${transaction.installments}x)`
                            : ""
                        }`}`}</p>
                      </GlobalColumn>

                      <GlobalColumn
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        gap="4px"
                      >
                        <p
                          style={{ textAlign: "right" }}
                          className="menu_item_label "
                        >
                          Valor
                        </p>
                        <p
                          style={{ textAlign: "right" }}
                          className="menu_item_info"
                        >{`${StringToCurrency(transaction.invoice)}`}</p>
                      </GlobalColumn>

                      <GlobalColumn
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        gap="4px"
                      >
                        <p className="menu_item_label ">Situação</p>
                        {getTransactionStatus(transaction.status)}
                      </GlobalColumn>

                      <GlobalColumn
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        gap="4px"
                      >
                        <p className="menu_item_label ">Código Autorizador</p>
                        <p className="menu_item_info">{`${
                          //relatorio.authorizerCode ||
                          transaction.authorizerCode || "-"
                        }`}</p>
                      </GlobalColumn>

                      <GlobalColumn
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        gap="4px"
                      >
                        <p className="menu_item_label ">PAN</p>
                        <p className="menu_item_info">
                          {
                            //relatorio.pan ||
                            transaction.pan || "-"
                          }
                        </p>
                      </GlobalColumn>

                      <GlobalColumn
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        gap="4px"
                      >
                        <p className="menu_item_label ">NSU</p>
                        <p className="menu_item_info">
                          {
                            //relatorio.atk ||
                            transaction.atk || "-"
                          }
                        </p>
                      </GlobalColumn>

                      <GlobalColumn
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        gap="4px"
                      >
                        <p className="menu_item_label ">itk</p>
                        <p className="menu_item_info">
                          {
                            //relatorio.itk ||
                            transaction.itk || "-"
                          }
                        </p>
                      </GlobalColumn>
                    </S.GridContainer>
                  );
                })}
              </S.MenuItem>
            )}
          </>
        )}

        {localPayment && hasPrint ? (
          <div style={{ display: "none" }}>
            <PaymentDetailPDF payment={localPayment} />
          </div>
        ) : null}
      </S.ModalContainer>
    </S.Modal>
  );
}
