/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable import/no-duplicates */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable consistent-return */
/* eslint-disable new-cap */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable consistent-return */
/* eslint-disable new-cap */
import { useEffect, useState } from "react";
import jsPDF from "jspdf";
import { parseISO, format } from "date-fns";
import pt from "date-fns/locale/pt-BR";

import * as S from "./styles";

import { base64Font } from "./base64Font";
import { base64FontBold } from "./base64FontBold";
import { base64FontSemiBold } from "./base64FontSemiBold";
import { Payment } from "../../models/model";
import { maskCustomProcess, maskDocument } from "../../utils/formatacaoDoc";
import { billIconGreen, iconOrange, tjIconBlue } from "./PDFImages";
import { statusesData, transactionStatuses } from "../../utils/statusesList";
import moment from "moment";
import { StringToCurrency } from "../../utils/StringToCurrency";
import { tr } from "date-fns/locale";
import { paymentMethodList } from "../../utils/paymentMethodList";

interface PaymentDetailPDFProps {
  payment: Payment;
}

export const PaymentDetailPDF = ({ payment }: PaymentDetailPDFProps): any => {
  const logo = tjIconBlue;
  const logoGreen = billIconGreen;
  const logoPigz = iconOrange;
  const paymentLastTransaction = payment.lastTransaction;

  function dataFormat(data: string): string {
    const Date = parseISO(data);
    const formattedDate = format(Date, "dd/MM/yyyy", {
      locale: pt,
    });

    return formattedDate.replace("  ", "");
  }

  function invoiceDescription(invoice: any): string {
    if (invoice?.extras?.custa) {
      if (invoice?.extras?.custa[0]) {
        return invoice.extras.custa[0].descricao;
      }
    }
    if (invoice?.extras?.description) {
      return invoice.extras?.description;
    }
    if (invoice?.description) {
      return invoice.description;
    }

    return "";
  }

  useEffect(() => {
    setTimeout(() => {
      const divId = document.getElementById("pdfPrint") as HTMLElement;
      const printDoc = new jsPDF({
        unit: "px", // set the units of measurement to px
        format: "A4", // set the 'paper' size
        userUnit: 72, // set the DPI here. Web uses 72 but you can change to 150 or 300
      });
      // const pdf = new jsPDF({
      //   orientation: 'portrait',
      //   unit: 'px',
      //   format: [canvas.width, canvas.height]
      // });
      // const printDoc = new jsPDF('portrait', 'pt', 'A4')

      // printDoc.addFileToVFS(
      //   "../../assets/Fonts/Roboto/Roboto-Medium.ttf",
      //   "Inter"
      // );
      printDoc.addFileToVFS("Roboto-Regular.ttf", base64Font);
      printDoc.addFileToVFS("Roboto-Bold.ttf", base64FontBold);
      printDoc.addFileToVFS("Roboto-SemiBold.ttf", base64FontSemiBold);

      printDoc.addFont("Roboto-Regular.ttf", "Roboto", "normal");
      printDoc.addFont("Roboto-Bold.ttf", "Roboto", "bold");
      printDoc.addFont("Roboto-SemiBold.ttf", "Roboto", "semi-bold");
      //   printDoc.setFont("Inter");
      printDoc.setFont("Roboto");

      printDoc.html(divId, {
        callback(pdf) {
          pdf.save(`pagamento_${payment.id}.pdf`);
        },
      });
    }, 1000);
  }, []);

  function StringToLocalDate(stringDate: string) {
    return moment(stringDate).format("DD/MM/YYYY HH:mm");
  }
  function getStatus() {
    const find = statusesData.find((f) => f.value === payment.status);
    if (find) {
      return find.label;
    }
    return payment.status;
  }
  const firstInvoice = payment?.invoices[0];

  const clientName = payment.transactions[0]
    ? payment.transactions[0].name
    : "-";
  const clientDoc =
    payment.doc && payment.doc.length > 0
      ? payment.doc
      : payment.transactions[0] && payment.transactions[0].doc
      ? payment.transactions[0].doc
      : "";
  function getType(transaction: any) {
    const find = paymentMethodList.find(
      (f) => `${f.value}` === `${transaction.type}`
    );

    return find?.label || "-";
  }

  function getTransactionStatus(transactionStatus?: number) {
    if (transactionStatus !== undefined) {
      const find = transactionStatuses.find(
        (f) => f.value === transactionStatus
      );
      if (find) {
        return find.label;
      }
    }
    return transactionStatus || "-";
  }

  return (
    <S.Container
      id="pdfPrint"
      style={{
        margin: "auto",
        width: "450px",
        padding: "0 16px",
        minHeight: "600px",
      }}
    >
      <div
        style={{
          width: "100%",
          height: 10,
        }}
      />

      <S.Column
        style={{ alignItems: "center", width: "100%", marginBottom: 12 }}
      >
        <S.Row>
          <S.Column>
            <img
              alt="Logo"
              height={32}
              width={160}
              src={logo}
              style={{ marginBottom: 10 }}
            />
          </S.Column>
        </S.Row>
      </S.Column>
      <S.HeaderComponentTitle>
        <p>{`Detalhes do pagamento`}</p>
        <p>
          {`R$ ${
            Number(payment.total).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
            }) || "-"
          }`}
        </p>
      </S.HeaderComponentTitle>
      <S.Line />
      <S.GridContainer gridRemplateColumns="1fr 1fr 1fr">
        <S.Column>
          <S.InfoTitle>Status</S.InfoTitle>
          <S.InfoLabel>{getStatus()} </S.InfoLabel>
        </S.Column>
        <S.Column>
          <S.InfoTitle>Pagamento</S.InfoTitle>
          <S.InfoLabel>
            {paymentLastTransaction?.paymentDate
              ? StringToLocalDate(paymentLastTransaction?.paymentDate || "")
              : "-"}
          </S.InfoLabel>
        </S.Column>
        <S.Column>
          <S.InfoTitle>Origem</S.InfoTitle>
          <S.InfoLabel>{payment?.device?.name}</S.InfoLabel>
        </S.Column>
      </S.GridContainer>
      <S.WeakLine />

      <S.GridContainer gridRemplateColumns="1fr 1fr">
        <S.Column>
          <S.InfoTitle>Identificador do pagamento</S.InfoTitle>
          <S.InfoLabel>{payment.id || "-"} </S.InfoLabel>
        </S.Column>
        <S.Column>
          <S.InfoTitle>Provedor</S.InfoTitle>
          <S.InfoLabel>{paymentLastTransaction?.provider || "-"}</S.InfoLabel>
        </S.Column>
      </S.GridContainer>

      <S.HeaderComponentTitle>
        <p>Detalhes da cobrança</p>
      </S.HeaderComponentTitle>
      <S.Line />

      <S.GridContainer gridRemplateColumns="1fr 1fr 1fr">
        <S.Column>
          <S.InfoTitle>Tipo</S.InfoTitle>
          <S.InfoLabel>Fatura </S.InfoLabel>
        </S.Column>
        <S.Column>
          <S.InfoTitle>Valor do documento</S.InfoTitle>
          <S.InfoLabel>{StringToCurrency(payment.total)}</S.InfoLabel>
        </S.Column>
        {payment.created ? (
          <S.Column>
            <S.InfoTitle>Criado em</S.InfoTitle>
            <S.InfoLabel>{StringToLocalDate(payment.created)}</S.InfoLabel>
          </S.Column>
        ) : null}
      </S.GridContainer>

      <S.WeakLine />

      <S.GridContainer gridRemplateColumns="1fr 1fr">
        <S.Column>
          <S.InfoTitle>Empresa</S.InfoTitle>
          <S.InfoLabel>{payment?.merchant?.name || "-"} </S.InfoLabel>
        </S.Column>
        <S.Column>
          <S.InfoTitle>
            {" "}
            {firstInvoice.content?.extras ? "Processo" : "Documento"}
          </S.InfoTitle>
          <S.InfoLabel>
            {firstInvoice.content?.extras
              ? maskCustomProcess(`${firstInvoice?.contentRef || "-"}`)
              : maskDocument(`${firstInvoice?.contentRef || "-"}`)}
          </S.InfoLabel>
        </S.Column>
      </S.GridContainer>
      <S.WeakLine />
      <S.GridContainer gridRemplateColumns="1fr">
        <S.Column>
          <S.InfoTitle>Guias</S.InfoTitle>

          {payment.invoices.map((invoiceItem) => (
            <S.GridContainer customGap="0px" gridRemplateColumns="1fr">
              <S.InvoiceRowBetween>
                <S.InvoiceRow>
                  <S.InfoLabel>
                    {payment?.merchant?.id === 6
                      ? invoiceItem.id || "-"
                      : invoiceItem.contentRefDate || "-"}
                  </S.InfoLabel>
                  <S.InfoLabel>
                    {payment?.merchant?.id === 6
                      ? invoiceDescription(invoiceItem.content) || "-"
                      : ""}
                  </S.InfoLabel>
                </S.InvoiceRow>

                <S.InfoLabel>
                  {`R$ ${invoiceItem.total?.toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                  })}`}
                </S.InfoLabel>
              </S.InvoiceRowBetween>

              {invoiceItem?.content?.extras?.observacao ? (
                <S.InvoiceRow>
                  <S.InfoLabel>Observação </S.InfoLabel>
                  <S.InfoLabel>
                    {invoiceItem?.content?.extras?.observacao || "-"}
                  </S.InfoLabel>
                </S.InvoiceRow>
              ) : null}
            </S.GridContainer>
          ))}
        </S.Column>
      </S.GridContainer>

      <S.HeaderComponentTitle>
        <p>Detalhes do Cliente</p>
      </S.HeaderComponentTitle>
      <S.Line />
      <S.GridContainer gridRemplateColumns="1fr 1fr">
        <S.Column>
          <S.InfoTitle>Nome</S.InfoTitle>
          <S.InfoLabel>{clientName || "-"} </S.InfoLabel>
        </S.Column>
        <S.Column>
          <S.InfoTitle>Documento</S.InfoTitle>
          <S.InfoLabel>{clientDoc || "-"} </S.InfoLabel>
        </S.Column>
      </S.GridContainer>

      <S.HeaderComponentTitle>
        <p>Detalhes das transações</p>
      </S.HeaderComponentTitle>
      <S.Line />
      {payment.transactions.map((transaction) => {
        return (
          <S.GridContainer gridRemplateColumns="1fr 1fr 1fr">
            <S.Column>
              <S.InfoTitle>Tipo</S.InfoTitle>
              <S.InfoLabel>{`${getType(transaction)} ${`${
                transaction.installments ? `(${transaction.installments}x)` : ""
              }`}`}</S.InfoLabel>
            </S.Column>

            <S.Column>
              <S.InfoTitle>Valor</S.InfoTitle>
              <S.InfoLabel>{`${StringToCurrency(
                transaction.invoice
              )}`}</S.InfoLabel>
            </S.Column>

            <S.Column>
              <S.InfoTitle>Status</S.InfoTitle>
              <S.InfoLabel>{`${getTransactionStatus(
                transaction.status
              )}`}</S.InfoLabel>
            </S.Column>

            <S.Column>
              <S.InfoTitle>Código Autorizador</S.InfoTitle>
              <S.InfoLabel>{`${
                //relatorio.authorizerCode ||
                transaction.authorizerCode || "-"
              }`}</S.InfoLabel>
            </S.Column>

            <S.Column>
              <S.InfoTitle>PAN</S.InfoTitle>
              <S.InfoLabel>{`${
                //relatorio.authorizerCode ||
                transaction.pan || "-"
              }`}</S.InfoLabel>
            </S.Column>

            <S.Column>
              <S.InfoTitle>NSU</S.InfoTitle>
              <S.InfoLabel>{`${
                //relatorio.authorizerCode ||
                transaction.atk || "-"
              }`}</S.InfoLabel>
            </S.Column>
            <S.Column>
              <S.InfoTitle>ITK</S.InfoTitle>
              <S.InfoLabel>{`${
                //relatorio.authorizerCode ||
                transaction.itk || "-"
              }`}</S.InfoLabel>
            </S.Column>
          </S.GridContainer>
        );
      })}

      <S.SlipFooter>
        <div className="slipfooter_row">
          <img height={12} width={28} src={logoGreen} alt="bill" />
          <p>Arrecadação Multicanal</p>
        </div>
        <div className="slipfooter_row">
          <p className="slipfooter_label">Desenvolvido por </p>
          <img src={logoPigz} height={12} width={36} alt="pigz" />
        </div>
      </S.SlipFooter>
    </S.Container>
  );
};
