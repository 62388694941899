export const statusesData = [
  { label: "", value: -1, iconColor: "#D6584C" },
  { label: "Rascunho", value: 0, iconColor: "#65AAEB" },
  { label: "Em Processamento", value: 1, iconColor: "#65AAEB" },
  { label: "Cancelado", value: 2, iconColor: "#757575" },
  { label: "Erro no Pagamento", value: 3, iconColor: "#D6584C" },
  { label: "Pendente", value: 4, iconColor: "#EFB645" },
  { label: "Pagamento Concluído", value: 5, iconColor: "#33BC73" },
  { label: "Conflito de Pagamento", value: 7, iconColor: "#FB7941" },
  { label: "CHARGEBACK", value: 8, iconColor: "#9270FB" },
  { label: "Pagamento Rejeitado", value: 9, iconColor: "#D6584C" },
  { label: "Aguardando Pagamento", value: 10, iconColor: "#EFB645" },
  { label: "Pagamento Restrito", value: 11, iconColor: "#BDBDBD" },
  { label: "Valor Pago em Excesso", value: 12, iconColor: "#33BC73" },
];

export const situacoesStatuses = [
  //{ value: '', label: '' },
  { value: 0, label: "Rascunho" },
  { value: 1, label: "Em Processamento" },
  { value: 2, label: "Cancelado" },
  { value: 3, label: "Erro no Pagamento" },
  { value: 4, label: "Pendente" },
  { value: 5, label: "Pagamento Concluído" },
  { value: 7, label: "Conflito de Pagamento" },
  { value: 8, label: "Chargeback" },
  { value: 9, label: "Pagamento Rejeitado" },
  { label: "Aguardando Pagamento", value: 10 },
  { label: "Pagamento Restrito", value: 11 },
  { label: "Valor Pago em Excesso", value: 12 },
];

export const transactionStatuses = [
  { label: "Indefinido", value: -1, iconColor: "#D6584C" },
  { label: "Em Processamento", value: 0, iconColor: "#65AAEB" },
  { label: "Pago", value: 1, iconColor: "#33BC73" },
  { label: "Cancelado", value: 2, iconColor: "#757575" },
  { label: "Falha na Transação", value: 3, iconColor: "#D6584C" },
  { label: "Chargeback", value: 4, iconColor: "#9270FB" },
  { label: "Aguardando Transação", value: 5, iconColor: "#EFB645" },
  { label: "Pago em excesso", value: 12, iconColor: "#33BC73" },
];


export const paymentStatusesData = [
  { label: "Fatura indisponivel para pagamento", value: -1, iconColor: "#D6584C" },
  { label: "Aberta", value: 0, iconColor: "#EFB645" },
  { label: "Aberta", value: null, iconColor: "#EFB645" },
  { label: "Paga", value: 1, iconColor: "#33BC73" },
  
];